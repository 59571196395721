import React from 'react'
import DatePicker from 'react-datepicker';
import dateUtils from '../utils/dateUtils'

class Converter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            date: new Date()
        }

        this.getAL = this.getAL.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    getAL() {
        var al = dateUtils.getAnnoLucis(this.state.date);

        return "Day " + al[0] + " of " + al[1] + " A∴L∴";
    }

    onChange(newDate) {
        this.setState({
            date: newDate
        });
    }

    render() {
        let al = this.getAL();

        return <div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className='column'>
                    <h1>{al}</h1>
                </div>
                <div className='column'>
                    <DatePicker
                        dateFormat="yyyy/MM/dd"
                        selected={this.state.date}
                        onChange={this.onChange}
                        inline
                        showYearDropdown
                        showMonthDropdown
                    />
                </div>
            </div>
        </div>
    }
}

export default Converter;