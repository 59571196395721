import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import './App.css';
import Converter from './Converter/Converter';
// import Inforamtion from './Information/Information';

class App extends React.Component {
    render() {
        return (
            <div className="App" >
                <header>
                    <h1>Anno Lucis</h1>
                </header>
                <div className="App-main">
                    <Converter />
                    {/* <Inforamtion /> */}
                </div>
                <footer>
                    <h4>Created by G∴Y∴ All rights reserved Ⓡ</h4>
                </footer>
            </div>
        );
    }
}

export default App;
