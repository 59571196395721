import moment from 'moment';

function getDayOfTheYear(date) {
    return date.diff(moment([date.year(), 0, 1]), 'days') + 1;
}

function getAnnoLucis(date) {
    let selectedDate = moment(date).startOf('day');
    let year = selectedDate.year() + 4000;
    let day;

    let eniovDen = moment([selectedDate.year(), 5, 24]);

    if (selectedDate > eniovDen) {
        year++;
        day = getDayOfTheYear(selectedDate) - getDayOfTheYear(eniovDen);
    } else {
        eniovDen = moment([selectedDate.year() - 1, 5, 24]);
        day = getDayOfTheYear(selectedDate) + getDayOfTheYear(moment([selectedDate.year() - 1, 11, 31])) - getDayOfTheYear(eniovDen);
    }

    return [day, year];
}

export default {
    getDayOfTheYear,
    getAnnoLucis
}